import React from "react"

import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(1),
  },
  description: {
    "& table": {
      "& td": {
        padding: "5px 10px",
        border: `3px ${theme.palette.common.white} solid`,
      },
    },
  }
}))

const Section = ({ title, description, isCenter }) => {
  const classes = useStyles()
  const justifyContent = isCenter ? "center" : "flex-start"
  return (
    <Box
      mb={3}
      display="flex"
      alignItems={justifyContent}
      flexDirection="column"
    >
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
      <Typography
        variant="body1"
        className={classes.description}
        component="div"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </Box>
  )
}

Section.defaultProps = {
  title: undefined,
  description: undefined,
  isCenter: false,
}

export default Section
