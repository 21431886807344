import React from "react"

import Title from "./Title"
import Description from "./Description"
import Section from "./Section"
import PricingPackage from "./PricingPackage"

const mapComponents = {
  title: Title,
  description: Description,
  section: Section,
  pricing_package: PricingPackage,
}

const DynamicComponent = props => {
  const Component = mapComponents[props.name]
  return Component ? <Component {...props} /> : null
}
export default DynamicComponent
