import React from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"

const Description = ({ description, isCenter }) => {
  const justifyContent = isCenter ? "center" : "flex-start"
  return (
    <Box
      mb={3}
      display="flex"
      flexDirection="column"
      alignItems={justifyContent}
    >
      {description && (
        <Typography
          variant="body1"
          component="div"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </Box>
  )
}

Description.defaultProps = {
  description: undefined,
  isCenter: false,
}

export default Description
