import React from "react"
// import cx from "classnames"
import MuiTable from "@material-ui/core/Table"
import TableRow from "@material-ui/core/TableRow"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"

// const TABLE_1 = 1
// const TABLE_2 = 2

const useStyles = makeStyles(theme => ({
  table: {
    "& td": {
      border: `${theme.spacing(0.3)}px ${theme.palette.common.white} solid`,
    },
  },
  pakiet: {},
  green: {
    fontWeight: "bold",
    color: theme.palette.success.main,
  },
  // red: {
  //   fontWeight: "bold",
  //   color: theme.palette.error.main,
  // },
}))

const Table = ({ data, table }) => {
  const classes = useStyles()
  const parsedData = JSON.parse(data)

  return (
    <MuiTable className={classes.table} aria-label="Pakiet">
      <TableBody>
        <TableRow>
          <TableCell align="center" className={classes.pakiet}>
            <Typography variant="h1">{parsedData.pakiet}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center" className={classes.green}>
            <Typography variant="body1">
              <strong>{parsedData.wynajecie_sali}</strong>
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            align="center"
            className={classes.green}
            // className={cx({
            //   [classes.green]: table > TABLE_1,
            //   [classes.red]: table <= TABLE_1,
            // })}
          >
            <Typography variant="body1">
              <strong>{parsedData.atrakcje}</strong>
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            align="center"
            className={classes.green}
            // className={cx({
            //   [classes.green]: table > TABLE_2,
            //   [classes.red]: table <= TABLE_2,
            // })}
          >
            <Typography variant="body1">
              <strong>{parsedData.catering}</strong>
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center">
            <Typography variant="body1">{parsedData.dzieci_8}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center">
            <Typography variant="body1">{parsedData.dzieci_15}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center">
            <Typography variant="body1">{parsedData.dzieci_25}</Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </MuiTable>
  )
}

export default Table
