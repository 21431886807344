import React from "react"
import cx from "classnames"
import Box from "@material-ui/core/Box"
import Hidden from "@material-ui/core/Hidden"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"

import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Layout from "../components/layout"
import DynamicComponent from "../components/dynamicComponent"

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(25),
    paddingBottom: theme.spacing(12.5),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(8),
    },
  },
  first: {
    transform: "rotate(-10deg)"
  },
  last: {
    transform: "rotate(10deg)"
  },
  grid: {
    marginBottom: theme.spacing(12)
  },

  gridItem: {
    width: "33.3333%",
    transform: "scale(1.2)",
    zIndex: 1,
    "&:nth-child(2)": {
      position: "relative",
      left: 20,
      zIndex: 2,
      transform: "scale(1.3)",
    },
    "& img": {
      objectFit: 'contain !important'
    }
  },
  imageMobile: {
    "& img": {
      objectFit: 'contain !important'
    }
  }
}))

const BirthdayPage = ({ data = {} }) => {
  const classes = useStyles()
  const { datoCmsBirthdayOfferPage = {} } = data
  const { seo = {}, sections = [], desktopImages = [], moibleImages = [] } = datoCmsBirthdayOfferPage

  return (
    <Layout bgColor="#B136FF">
      <Seo title={seo.title} description={seo.description} />
      <Box className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <Hidden smDown>
            <Grid container className={classes.grid}>
              {!!desktopImages && desktopImages.map((image, index) => (
                <Grid className={classes.gridItem} key={index}>
                  <GatsbyImage
                    alt="Zdjęcie sekcja o nas"
                    image={getImage(image)}
                    className={cx(classes.image, {
                      [classes.first]: !index,
                      [classes.last]: index === desktopImages.length - 1,
                    })}
                  />
                </Grid>
              ))}
            </Grid>
          </Hidden>
          {moibleImages && moibleImages[0] && (
            <Hidden mdUp>
              <Box my={3} display="flex" justifyContent="center">
                <GatsbyImage
                  alt="Zdjęcie sekcja o nas"
                  image={getImage(moibleImages[0])}
                  className={cx(classes.imageMobile)}
                />
              </Box>
            </Hidden>
          )}
        </Container>
        <Container maxWidth="md" className={classes.container}>
          {sections.map((section, index) => (
            <DynamicComponent
              key={index}
              name={section.component}
              {...section}
            />
          ))}
        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query BirthdayOfferPageQuery {
    datoCmsBirthdayOfferPage {
      seo {
        title
        description
      }
      desktopImages {
        gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, width: 350, height: 350)
      }
      moibleImages {
        gatsbyImageData(placeholder: NONE, layout: CONSTRAINED, width: 450, height: 450)
      }
      sections {
        ... on DatoCmsTitle {
          id
          title
          component
          isCenter
        }
        ... on DatoCmsDescription {
          id
          description
          component
          isCenter
        }
        ... on DatoCmsSekcja {
          id
          title
          description
          component
          isCenter
        }
        ... on DatoCmsPricingPackageMt {
          package1
          package2
          package3
          component
        }
        ... on DatoCmsPricingPackageFu {
          package1
          package2
          package3
          component
        }
      }
    }
  }
`

export default BirthdayPage
