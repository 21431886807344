import React from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"

// import { makeStyles } from "@material-ui/core/styles"

// const useStyles = makeStyles(theme => ({
//   root: {},
// }))

const Title = ({ title, isCenter }) => {
  // const classes = useStyles()
  const justifyContent = isCenter ? "center" : "flex-start"
  return (
    <Box
      mb={3}
      display="flex"
      alignItems={justifyContent}
      flexDirection="column"
    >
      {title && (
        <Typography
          variant="h1"
          component="div"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
    </Box>
  )
}

Title.defaultProps = {
  title: undefined,
  isCenter: false,
}

export default Title
