import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"

// import { makeStyles } from "@material-ui/core/styles"

import Table from "./Table"

// const useStyles = makeStyles(theme => ({
//   root: {},
// }))

const PricingPackage = ({ package1, package2, package3 }) => {
  // const classes = useStyles()

  return (
    <Box mb={10}>
      <Grid container spacing={10}>
        <Grid item md={4} sm={12}>
          <Table table={1} data={package1} />
        </Grid>
        <Grid item md={4} sm={12}>
          <Table table={2} data={package2} />
        </Grid>
        <Grid item md={4} sm={12}>
          <Table table={3} data={package3} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default PricingPackage
